import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { UserVerificationsStyles } from "../../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, getErrorMessage, validatePhoneNumber } from "../../../commons/Utils";
import {
    PostVerificationSendPhone,
    PostVerificationSendEmail,
    PostVerificationValidationOtpEmail,
    PostVerificationValidationOtpPhone,
} from "../../../services/ReasonService";
import { PrefixPhone } from "../../PrefixPhone/PrefixPhone";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { secureValues, Size, verificatedValues } from "../../../commons/EnumsGeneral";
import { SelectorSwitch } from "../../CommonComponents/SelectorSwitch";
import history from "../../../services/history.js";
import { EnumPages } from "../../../commons/EnumPages.js";
import { setSecureItem } from "../../../services/Commons.js";

export function DataVerification(props) {

    const { verificableData, countryList, finishOperation, setOpenUnification, firstInfo } = props;

    const commonClasses = CommonStyles();
    const classes = UserVerificationsStyles();
    const { t, i18n } = useTranslation();
    const validator = require("react-email-validator");

    const [showPhoneSelector, setShowPhoneSelector] = useState(false);
    const [country, setCountry] = useState({});
    const [verificableDataList, setVerificableDataList] = useState([]);
    const [verificable01, setVerificable01] = useState("");
    const [sended01, setSended01] = useState(false);
    const [otp01, setOtp01] = useState("");
    const [otp01Error, setOtp01Error] = useState(false);
    const [otp01ErrorText, setOtp01ErrorText] = useState("");
    const [verificable02, setVerificable02] = useState("");
    const [sended02, setSended02] = useState(false);
    const [verificable02error, setVerificable02Error] = useState(false);
    const [verificable02ErrorText, setVerificable02ErrorText] = useState("");
    const [confirmed01, setConfirmed01] = useState(false);
    const [confirmed02, setConfirmed02] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgressSending, setCircularProgressSending] = useState(false);

    useEffect(() => {
        if (firstInfo) {
            let auxVerificableData = [];
            let auxEmails = verificableData.userEmail.filter((email) => email.idConTypeVerification >= verificatedValues.email);
            let auxPhones = verificableData.userPhone.filter((phone) => phone.idConTypeVerification >= verificatedValues.phone);
            auxEmails.forEach((email) => {
                auxVerificableData.push(email.email);
            });
            auxPhones.forEach((phone) => {
                let auxCountry = countryList.find((country) => country.id === phone.idCountry);
                let auxPhone = auxCountry.phonePrefix + "-" + phone.phone;
                auxVerificableData.push(auxPhone);
            });
            setVerificableDataList(auxVerificableData);
            setVerificable01(auxVerificableData[0]);
        };
    }, [firstInfo]);

    useEffect(() => {
        if (!checkIsNullUndefined(verificable02)) {
            checkValidity(verificable02);
        };
    }, [verificable02]);

    useEffect(() => {
        if (confirmed02) {
            setSecureItem(secureValues.copyUser, verificable02);
            setOpenUnification(true);
        };
    }, [confirmed02]);

    const handleChangeVerificable01 = (value) => {
        setVerificable01(value);
    };

    const handleChangeVerificable02 = (value) => {
        setVerificable02(value);
    };

    const handleSend = () => {
        setCircularProgressSending(true);
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        let isEmail = auxVerificable.includes("@");
        if (!isEmail && confirmed01) {
            auxVerificable = country.phonePrefix + "-" + auxVerificable;
        };
        let auxCall = isEmail ? PostVerificationSendEmail : PostVerificationSendPhone;
        let auxSetSended = confirmed01 ? setSended02 : setSended01;
        auxCall(auxVerificable).then((response) => {
            if (response && !response.Status) {
                auxSetSended(true);
            } else {
                //Alerta
                finishOperation("error", getErrorMessage(response, t));
                auxSetSended(false);
            };
            setCircularProgressSending(false);
        });
    };

    const handleCancel = () => {
        history.push("Index/" + EnumPages.UserVerifications);
    };

    const handleValidate = () => {
        setCircularProgress(true);
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        let isEmail = auxVerificable.includes("@");
        let auxCall = isEmail ? PostVerificationValidationOtpEmail : PostVerificationValidationOtpPhone;
        let auxConfirmed = confirmed01 ? setConfirmed02 : setConfirmed01;
        auxCall(auxVerificable, otp01).then((response) => {
            if (response && !response.Status) {
                auxConfirmed(true);
                setOtp01("");
            } else {
                //Alerta
                let auxType = isEmail ? t("email") : t("phone");
                auxType = auxType.toLowerCase();
                let auxText = t("goToUnifyAccountsOtpError").replace("{dataType}", auxType);
                setOtp01Error(true);
                setOtp01ErrorText(auxText);
                finishOperation("error", getErrorMessage(response, t));
            };
            setCircularProgress(false);
        });
    };

    // Hacer que valga para las 2 otps
    const handleChangeOtp = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value === "" || re.test(value)) {
            setOtp01(value);
            setOtp01Error(false);
            setOtp01ErrorText("");
        };
    };

    const handleSwitchType = (emailSelected) => {
        handleClear();
        setShowPhoneSelector(!emailSelected);
    };

    const handleSelectorChange = (country) => {
        let auxText = t("loginFormatError");
        setCountry(country);
        if (verificable02 === "" || validatePhoneNumber(verificable02, country)) {
            auxText = "";
        };
        setVerificable02Error(!checkIsNullUndefined(auxText));
        setVerificable02ErrorText(auxText);
    };

    const checkValidity = (value) => {
        let auxText = t("loginFormatError");
        if (showPhoneSelector) {
            if (value === "" || validatePhoneNumber(value, country)) {
                auxText = "";
            };
        } else {
            if (value === "" || checkIsValidEmail(value)) {
                auxText = "";
            };
        };
        setVerificable02Error(!checkIsNullUndefined(auxText));
        setVerificable02ErrorText(auxText);
    };

    const checkIsValidEmail = (value) => {
        return validator.validate(value);
    };

    const handleDisabled = () => {
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        return checkIsNullUndefined(auxVerificable) || checkIsNullUndefined(otp01);
    };

    const handleClear = () => {
        setOtp01("");
        setOtp01Error(false);
        setOtp01ErrorText("");
        setVerificable02("");
        setSended02(false);
        setVerificable02Error(false);
        setVerificable02ErrorText("");
    };

    return (
        <>
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={commonClasses.dialogTitleText}>
                            {confirmed01 ? t("goToUnifyAccountsTitle2") : t("goToUnifyAccountsTitle1")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h6" className={commonClasses.dialogTitleText} >
                            {confirmed01 ? t("goToUnifyAccountsText2") : t("goToUnifyAccountsText1")}
                        </Typography>
                    </Grid>
                    {confirmed01 ? (
                        <>
                            <Grid item xs={12} style={{ margin: "1em" }}>
                                <SelectorSwitch textA={t("UserEmail")} textB={t("registerPagePhone")} selectedA={!showPhoneSelector} handleSwitch={handleSwitchType} size={Size.medium} />
                            </Grid>
                            {showPhoneSelector ? (
                                <Grid item sm={4} xs={12} className={commonClasses.gridIdentity}>
                                    <PrefixPhone handleSelectorChange={handleSelectorChange} isLogin={true} masterCountryList={countryList} />
                                </Grid>
                            ) : null}
                            <Grid item sm={showPhoneSelector ? 5 : 9} xs={9}>
                                <TextField
                                    color="secondary"
                                    defaultValue={verificable02}
                                    value={verificable02}
                                    onInput={(e) => handleChangeVerificable02(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    name="otpEmail"
                                    autoFocus
                                    label={showPhoneSelector ? t("phone") : t("email")}
                                    type="text"
                                    error={verificable02error}
                                    helperText={verificable02ErrorText}
                                    required
                                    className={commonClasses.inputsStyle}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.buttonAdd}>
                                <CorreosButton
                                    variant={sended02 ? "outlined" : "contained"}
                                    color={sended02 ? "secondary" : "primary"}
                                    onClick={handleSend}
                                    disabled={checkIsNullUndefined(verificable02)}
                                    fullWidth
                                    circularProgress={circularProgressSending}
                                >
                                    {sended02 ? t("reSend") : t("send")}
                                </CorreosButton>
                            </Grid>
                            {sended02 && (
                                <Grid item xs={12}>
                                    <TextField
                                        color="secondary"
                                        defaultValue={otp01}
                                        value={otp01}
                                        onChange={(e) => handleChangeOtp(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        name="otpEmail"
                                        autoFocus
                                        label={t("lOtp")}
                                        type="text"
                                        error={otp01Error}
                                        helperText={otp01ErrorText}
                                        required
                                        className={commonClasses.inputsStyle}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid item xs={9}>
                                <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
                                    <InputLabel label={t("goToUnifyAccountsData")} color="secondary">
                                        {t("goToUnifyAccountsData")}
                                    </InputLabel>
                                    <Select
                                        native
                                        color="secondary"
                                        value={verificable01}
                                        onChange={(e) => handleChangeVerificable01(e.target.value)}
                                        label={t("goToUnifyAccountsData")}
                                    >
                                        {verificableDataList.map((auxData) => {
                                            return (
                                                <option key={auxData} value={auxData}>
                                                    {auxData}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} className={classes.buttonAdd}>
                                <CorreosButton
                                    variant={sended01 ? "outlined" : "contained"}
                                    color={sended01 ? "secondary" : "primary"}
                                    onClick={handleSend}
                                    disabled={checkIsNullUndefined(verificable01)}
                                    fullWidth
                                    circularProgress={circularProgressSending}
                                >
                                    {sended01 ? t("reSend") : t("send")}
                                </CorreosButton>
                            </Grid>
                            {sended01 && (
                                <Grid item xs={12}>
                                    <TextField
                                        color="secondary"
                                        defaultValue={otp01}
                                        value={otp01}
                                        onChange={(e) => handleChangeOtp(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        name="otpEmail"
                                        autoFocus
                                        label={t("lOtp")}
                                        type="text"
                                        error={otp01Error}
                                        helperText={otp01ErrorText}
                                        required
                                        className={commonClasses.inputsStyle}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        className={commonClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={commonClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleValidate}
                                className={commonClasses.dialogButtonRight}
                                disabled={handleDisabled()}
                                circularProgress={circularProgress}
                            >
                                {t("confirm")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};